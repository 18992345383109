export default [
  {
    identifier: "WM",
    currency: "usd",
    bank: {
      recipient: "Lemon Way",
      recipientAddress: "8 Rue du Sentier",
      recipientCity: "Paris",
      recipientPostCode: "75002",
      recipientCountry: "France",
      name: "Banco Sabadell",
      bankAddress: "Oscar Espla 37",
      bankCity: "Alicante",
      bankPostCode: "3007",
      bankCountry: "Spain",
      bic: "BSABESBBXXX",
      iban: "ES2200815375970070780390",
    },
  },
  {
    identifier: "WM",
    currency: "zar",
    bank: {
      recipient: "WealthPoint ZAR Wallets",
      recipientAddress: "",
      recipientCity: "",
      recipientPostCode: "",
      recipientCountry: "",
      name: "First National Bank",
      bankAddress: "Entrance 2, Somerset Mall, Corner of Broadway Boulevard & N2",
      bankCity: "Somerset West",
      bankPostCode: "7130",
      bankCountry: "South Africa",
      bic: "FIRNZAJJ",
      iban: "",
      bankBranchCode: "250655",
      bankAccountNo: "62768083538",
      bankAccountType: "Business Current",
    },
  },
];
