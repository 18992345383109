import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Task from './Task';

import TopBar from '../../components/TopBar';
import Sidebar from '../../components/Sidebar';
import LayoutMessages from '../../containers/LayoutMessages';
import Footer from '../../components/Footer';
import SystemAlert from '../../containers/SystemAlert';

import UIActions from '../../../redux/actions/ui.actions';
import UserActions from '../../../redux/actions/user.actions';
import PortfolioActions from '../../../redux/actions/portfolio.actions';
import WalletActions from '../../../redux/actions/wallets.actions';
import DemandPartnerActions from '../../../redux/actions/demand-partners.actions';
import InvestmentsActions from '../../../redux/actions/investments.actions';

import {
  listerThunk,
} from '../../../redux/thunks';

import PendoData from '../../../utils/PendoData';

import { PATH } from '../../../constants';

import './stylesheet.css';

/**
 * React Layout for default authenticated views.
 */
const AuthorisedLayout = ({
  children,
  drawer,
  dashbar,
  task,
  setDashbar,
  toggleTask,
  isLoading,
  ui,

  entities = [],
  activeEntity = undefined,
  switchActiveEntity,
  getCanInvest,

  route,
  
  lister,
  listerStatus,
  fetchLister,

  portfolioLoading = false,
  portfolios = [],
  fetchPortfolio,

  wallets = [],
  fetchWallets,
  
  demandPartners,
  
  fetchDemandPartners,
  fetchEntities,

  userLevels,
  fetchUserLevels,
  fetchCanInvest,
  fetchMinimums,

  userLoading,
  walletsLoading,
  canInvest,
  
  setCurrency,
  accountVerified,
  subsystem,
  className = '',
  investments,
  
  
}) => {

  const [isEligible, setEligibilty] = useState(true);

  useMemo(() => {
    if (dashbar && (route.includes('/profile/') || route.includes('/portfolio/') || route.includes('/wallet/') )) {
      return setDashbar(undefined);
    };
    return null;
  }, [dashbar, route]);

  useMemo(() => {
    if (demandPartners.length === 0) {
      fetchDemandPartners();
    }
  }, [demandPartners.length]);

  useMemo(() => {
    if ( (!lister || lister.subsystem !== subsystem) && listerStatus !== 'fetching') {
      fetchLister();
    }
  }, [lister, listerStatus, subsystem]);

  useMemo(() => {
    if (userLevels === undefined) {
      fetchUserLevels();
    }
  }, [userLevels]);

  useMemo(() => {
    if (activeEntity === undefined || entities.length === 0) {
      fetchEntities();
    }
    if(activeEntity?._id){
      // Note: This runs everytime activeEntity changes
      fetchCanInvest(activeEntity._id);
      fetchWallets(activeEntity._id);
      fetchMinimums(activeEntity._id, investments.map(p => p._id));
      fetchPortfolio(activeEntity._id);
    };

  }, [activeEntity]);

  const onClick = () => {
    if (task === true) {
      toggleTask({
        taskName: '',
        drawer: true,
        taskStep: 1,
        task: false,
      });
    }
  };

  useMemo(() => {
    const safeDocument = typeof document !== 'undefined' ? document : {};
    const html = safeDocument.documentElement;
    const { body } = safeDocument;
    
    if(task) {    
      html.style.position = 'relative'; /* [1] */
      html.style.overflow = 'hidden'; /* [2] */
      body.style.position = 'relative'; /* [1] */
      body.style.overflow = 'hidden'; /* [2] */
    } else {
      html.style.position = '';
      html.style.overflow = '';
      body.style.position = '';
      body.style.overflow = '';
      body.style.paddingRight = '';
    };

  }, [task]);

  useMemo(() => {
  
    if (activeEntity && !isLoading) {
      return PendoData({
        id: activeEntity.owner,
        email: activeEntity.email,
        full_name: activeEntity.name,
        gender: activeEntity.content.gender,
        country: activeEntity.content.nationality,
        kycStatus: activeEntity.kyc_status,
      });
    }

    return null;

  }, [activeEntity]);

  useMemo(() => {
    if(!userLoading) {
      const personalEntity = entities.find(e => e.content_type === 'personal');
      const investorType = personalEntity?.content?.investor_type;
      const investorProfile = personalEntity?.content?.investor_type_profile;
      if (!investorType || !investorProfile) {
        setEligibilty(false);
      };
    };
  }, [entities, userLoading]);

  const Eligibility = useCallback(() => {
    if(canInvest && !canInvest?.data?.have_access) {
      return <Redirect to={ PATH.UNSUPPORTED_COUNTRY } />;
    }
    if(!isEligible) {
      return <Redirect to={ PATH.BASIC_INFO } />;
    }
    return null;
    }, [canInvest, isEligible]);
  return (
    <>
      <Eligibility />
      <Sidebar 
        ui={ ui }
        entities={ entities }
        activeEntity={ activeEntity }
        lister={ lister }
        className={ `l-page__sidebar ${ task ? 'l-page__sidebar--disabled' : '' }` }
        switchActiveEntity={ (entity) => switchActiveEntity(entity) } 
      />

      { <SystemAlert /> }

      <TopBar 
        className={ `l-page__topbar ${ task ? 'l-page__topbar--disabled' : '' }` }
        wallets={ wallets }
        entities={ entities }
        activeEntity={ activeEntity }
        switchActiveEntity={ (entity) => switchActiveEntity(entity) }
        dashbar={ dashbar }
        lister={ lister }
      />

      <main
        className={ `l-page  ${ task ? 'l-page--disabled' : '' }` }
        onClick={ () => onClick() }
      >

        <LayoutMessages />

        { children }

      </main>
      
      <Footer className="c-footer--overlayed" />

      <div 
        className={ `l-page__task ${ task ? 'l-page__task--enabled' : '' }` }
      >
        <Task />
      </div>

      

    </>
  );

};

AuthorisedLayout.propTypes = {
  children: PropTypes.node,
  drawer: PropTypes.bool,
  dashbar: PropTypes.string,
  task: PropTypes.bool,
  isLoading: PropTypes.bool,
  toggleDashbar: PropTypes.func,
  toggleTask: PropTypes.func,
  activeEntity: PropTypes.object,
  isVerified: PropTypes.bool,
  isLegacyUser: PropTypes.bool,
  pendingInvestments: PropTypes.array,
  fundedInvestments: PropTypes.array,
  pledges: PropTypes.array,
  getPortfolio: PropTypes.func,
  getPledges: PropTypes.func,
  ui: PropTypes.object,

  portfolioLoading: PropTypes.bool,
  portfolios: PropTypes.array,
  fetchPortfolio: PropTypes.func,
  lister: PropTypes.object

};

const mapStateToProps = (state) => {
  const isLoading = [
    state.user.loading,
    state.countries.loading,
    state.demand_partners.loading,
    state.session.loading,
    state.investments.loading,
    state.pledges.loading,
    state.documents.loading,
  ].some(e => e);
  return {
  
    ui: state.ui.ui,
    drawer: state.ui.drawer,
    dashbar: state.ui.dashbar,
    task: state.ui.task,
  
    activeEntity: state.user.active_entity,
    entities: state.user.entities,

    
    portfolioLoading: state.portfolio.loading,
    accountVerified: state.session.accountVerified,
    totals: state.portfolio.totals,
    listerStatus: state.lister.status,
    lister: state.lister.data?.[0],
    wallets: state.wallets.wallet.wallets,
    route: state.router.location.pathname,
    canInvest: state.user.can_invest,
    demandPartners: state.demand_partners.items,
    userLevels: state.user.user_levels,
    userLoading: state.user.loading,
    walletsLoading: state.wallets.walletsLoading,
    isLegacyUser: state.session.legacy,
    subsystem: state.session.subsystem,
    isLoading,
    investments: state.investments.items,
  }
};

const mapDispatchToProps = (dispatch) => {
  return ({
    setDashbar: (type) => { dispatch(UIActions.setDashbar(type)); },
    toggleTask: (data) => { dispatch(UIActions.toggleTask(data)); },

    fetchWallets: (entity_id) => { dispatch(WalletActions.getAll(entity_id)); }, //eslint-disable-line
    fetchEntities: () => { dispatch(UserActions.getEntities()); },
    fetchCanInvest: (entityId) => { dispatch(UserActions.getCanInvest(entityId)); },
    fetchPortfolio: (entityId, currency) => { dispatch(PortfolioActions.getByEntity(entityId, currency)); },
    fetchDemandPartners: () => { dispatch(DemandPartnerActions.getAll()); },
    fetchUserLevels: () => { dispatch(UserActions.getUserLevels()); },
    fetchLister: () => { dispatch(listerThunk.fetchLister()); },
    fetchMinimums: (entity_id, product_ids) => { dispatch(InvestmentsActions.getMinimums(entity_id, product_ids))},
    switchActiveEntity: (entity) => { dispatch(UserActions.selectActiveEntity(entity)); },
    getCanInvest: (id) => { dispatch(UserActions.getCanInvest(id)); },

  });
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisedLayout);
