export default class DateExtensions {
  /**
   * Takes an epoch timestamp and convert it to a date .
   * @example
   * const date = epochDateConverter(21323423423);
   * @returns {Date} Returns a date from the given epoch value
   * And the date looks like this:
   * @example "2019-03-04"
   */
  static epochDateConverter = (timestamp = 1) => {
    const conversion = new Date(timestamp);
    try {
      return conversion.toISOString().split('T')[0];
    } catch {
      return undefined
    }
  };

  /**
   * A function to convert a epoch into a date object for display
   * @param {17889287788939}
   */
  static epochToDisplayDate = (epoch = 0) => {
    const day = new Date(epoch).getDate();
    const month = new Date(epoch).getMonth() + 1;
    const year = new Date(epoch).getFullYear();
    return {
      day : day >= 10 ? `${ day }` : `0${ day }`,
      month: month >= 10 ? `${ month }` : `0${ month }`,
      year
    }
  };

  /**
   * A function to convert date values from string to a UTC epoch
   * @param {1990} year 
   * @param {05} month 
   * @param {15} day 
   */
  static displayDateToUTCEpoch = (year, month, day) => {
    return new Date(Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))).getTime()
  };

/**
 * A function to parse the investment duration in string in to a object containing years, months and days
 * @param {'10Y3M15D'} duration 
 */
  static parseDuration = (duration = '0y0m0d') => {
    let re = /\d+?[yYmMdD]/g
    const matches = duration.match(re)
    const years = matches?.filter(m => m.toLowerCase().includes('y')).map(ys => parseInt(ys.split('y')?.[0], 10)).reduce((x, y) => x + y, 0)
    const months = matches?.filter(m => m.toLowerCase().includes('m')).map(ms => parseInt(ms.split('m')?.[0], 10)).reduce((x, y) => x + y, 0)
    const days = matches?.filter(m => m.toLowerCase().includes('d')).map(ds => parseInt(ds.split('d')?.[0], 10)).reduce((x, y) => x + y, 0)
    return {years, months, days}
  }

/**
 * A Function to add a duration of years months and days to a current epoch
 * @param {1610100363680} millis 
 * @param {10} years 
 * @param {4} months 
 * @param {15} days 
 */
  static addDuration = (millis = 0, years = 0, months = 0, days = 0) => {
    let t = new Date(millis)
    t.setFullYear(t.getFullYear() + years)
    t.setMonth(t.getMonth() + months)
    t.setDate(t.getDate() + days)
    return t.getTime()
  }

}
