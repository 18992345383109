import { INVESTMENT_TYPES } from '../types';
import Action from './helpers/action';

export default class InvestmentActions {
  /**
   * Retrieve a single investment
   * @example
   * getAll()
   */
  static getById = (id) => {
    return Action.create(INVESTMENT_TYPES.GETINVESTMENTBYID_BEGIN, { id });
  }

  /**
   * Retrieve a single investment
   * @example
   * getAll()
   */
  static getByIdSuccess = (investment) => {
    return Action.create(INVESTMENT_TYPES.GETINVESTMENTBYID_SUCCESS, { investment });
  }

  /**
   * Retrieve investment failed.
   * @example
   * error(errorObj)
   */
  static getByIdError = (errorObj) => {
    return Action.createError(INVESTMENT_TYPES.GETINVESTMENTBYID_FAILURE, { error: errorObj });
  }

  /**
   * Retrieve Fees for a user on a subsystem
   * @example
   * getFees()
   */
  static getFees = (productIds) => {
    return Action.create(INVESTMENT_TYPES.GET_FEES_BEGIN, { productIds });
  }

  /**
   * Retrieve a single investment
   * @example
   * getFeesSuccess()
   */
  static getFeesSuccess = (fees) => {
    return Action.create(INVESTMENT_TYPES.GET_FEES_SUCCESS, { fees });
  }

  /**
   * Retrieve fees failed.
   * @example
   * getFeesError
   */
  static getFeesError = (errorObj) => {
    return Action.createError(INVESTMENT_TYPES.GET_FEES_FAILURE, { error: errorObj });
  }

}
