import global from './Global';
import focus from './WM_Focus';
import geniusu from './GeniusU';
import xusa from './Xusa';
import edpf from './Edpf';
import privatewealth from './PrivateWealth';
import women from './Women';
import worldwidecapitalpartners from './worldwidecapitalpartners';
import rewardproperties from './reward-properties';
import rei from './rei';
import institutional from './wealthpoint';
import sapin from './sapin';
import justproperty from './just-property';
import stockcapital from './stockcapital';
import mywealth from './mywealth';
import ufc from './ufc';
import cbi from './cbi';
import yachties from './Yachties';
import neuma from './Neuma';
import legacybuildersglobal from './legacybuildersglobal';
import invest from './invest';
import igrowcapital from './igrowcapital';
import wealthindia from './wealthindia';
import apm from './apm';
import shariahwealth from './shariahwealth';
import demo1 from './demo1';
import invictusamg from './invictusamg';
import bt from './bt';
import associatedbroker from './associatedbroker';
import russellwallett from './russellwallett';
import investlife from './investlife';
import equitymultiple from './equitymultiple';


export default {
  global,
  geniusu,
  xusa,
  edpf,
  focus,
  privatewealth,
  women,
  worldwidecapitalpartners,
  rewardproperties,
  rei,
  institutional,
  sapin,
  justproperty,
  stockcapital,
  mywealth,
  ufc,
  cbi,
  yachties,
  neuma,
  legacybuildersglobal,
  invest,
  igrowcapital,
  wealthindia,
  apm,
  shariahwealth,
  demo1,
  invictusamg,
  bt,
  associatedbroker,
  russellwallett,
  investlife,
  equitymultiple,
};
