import { SESSION_TYPES } from '../types';
import Action from './helpers/action';

export default class SessionActions {
  /**
   * Signs in a user.
   * @example
   * signin({ email, password })
   */
  static signin = (data) => {
    return Action.create(SESSION_TYPES.SIGNIN_BEGIN, {
      email: data.email,
      password: data.password,
      subsystem: data.subsystem,
    });
  }

  /**
   * Sign in a user succeeded.
    */
  static signinSuccess = (userEntities, email, accountVerified, legacy, demandPartner, channelPartner, subsystem) => {
    return Action.create(SESSION_TYPES.SIGNIN_SUCCESS, {
      userEntities, email, accountVerified, legacy, demandPartner, channelPartner, subsystem,
    });
  }

  /**
   * Sign in a failed succeeded.
    */
  static signinFailure = (errorObj) => {
    return Action.createError(SESSION_TYPES.SIGNIN_FAILURE, { error: errorObj });
  }

  /**
   * Registers a user.
   * @example
   * signup({ email, password, receiveMarketing, receiveOpportunities })
   */
  static signup = (data) => {
    return Action.create(SESSION_TYPES.SIGNUP_BEGIN, data);
  };

  /**
   * Sign up a user succeeded.
    */
  static signupSuccess = (userEntities, accountVerified, username) => {
    return Action.create(SESSION_TYPES.SIGNUP_SUCCESS, { userEntities, accountVerified, username});
  }

  /**
   * Sign up a user failed.
    */
  static signupFailure = (errorObj) => {
    return Action.createError(SESSION_TYPES.SIGNUP_FAILURE, { error: errorObj });
  }

  /**
   * Signs out a user.
   * @example
   * signout()
   * @param retainSubsystem
   * Should the current subsystem be retained? default = true
   */
  static signout = (retainSubsystem = true) => {
    return Action.create(SESSION_TYPES.SIGNOUT, { retainSubsystem });
  }

  /**
   * Refreshes a jwt token
   */
  static refreshToken = () => {
    return Action.create(SESSION_TYPES.REFRESH_TOKEN_BEGIN);
  };

  /**
   * Refresh jwt token failed.
   */
   static refreshTokenFailure = (errorObj) => {
     return Action.createError(SESSION_TYPES.REFRESH_TOKEN_FAILURE, { error: errorObj });
   };

   /**
   * Refresh jwt token succeded.
   */
  static refreshTokenSuccess = (userEntities, accountVerified, legacy, demandPartner, channelPartner) => {
    return Action.create(SESSION_TYPES.REFRESH_TOKEN_SUCCESS, {
      userEntities, accountVerified, legacy, demandPartner, channelPartner,
    });
  };

  /**
   * Request reset password
   */
  static requestResetPassword = (email, system, subsystem, domainName) => {
    return Action.create(SESSION_TYPES.REQUEST_PASSWORD_BEGIN, { email, system, subsystem, domainName });
  };

  /**
   * Request reset password failed
   */
  static requestResetPasswordFailure = (errorObj) => {
    return Action.create(SESSION_TYPES.REQUEST_PASSWORD_FAILURE, { error: errorObj });
  };

  /**
   * Request reset password failed
   */
  static requestResetPasswordSuccess = () => {
    return Action.create(SESSION_TYPES.REQUEST_PASSWORD_SUCCESS);
  };

  /**
   * Verify email
   */
  static verifyEmail = (subsystem, token) => {
    return Action.create(SESSION_TYPES.VERIFY_EMAIL_BEGIN, { subsystem, token });
  };

  /**
   * Verify email
   */
  static verifyEmailSuccess = () => {
    return Action.create(SESSION_TYPES.VERIFY_EMAIL_SUCCESS);
  };

  /**
   * Verify email
   */
  static verifyEmailFailure = (errorObj) => {
    return Action.createError(SESSION_TYPES.VERIFY_EMAIL_FAILURE, { error: errorObj });
  };

  /**
   * Request reset password
   */
  static resetPassword = (token, subsystem, password) => {
    return Action.create(SESSION_TYPES.RESET_PASSWORD_BEGIN, { token, subsystem, password });
  };

  /**
   * Request reset password failed
   */
  static resetPasswordFailure = (errorObj) => {
    return Action.createError(SESSION_TYPES.RESET_PASSWORD_FAILURE, { error: errorObj });
  };

  /**
   * Request reset password succeeded
   */
  static resetPasswordSuccess = () => {
    return Action.create(SESSION_TYPES.RESET_PASSWORD_SUCCESS);
  };

  static hydrateState = () => {
    return Action.create(SESSION_TYPES.HYDRATE_STATE_BEGIN);
  };

  static hydrateStateSuccess = () => {
    return Action.create(SESSION_TYPES.HYDRATE_STATE_SUCCESS);
  };

  static hydrateStateFailure = () => {
    return Action.create(SESSION_TYPES.HYDRATE_STATE_FAILURE);
  };
}
